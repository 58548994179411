import { Subject } from 'rxjs';
import { IExternalChannel } from './interfaces/IExternalChannel';
import { IOptions } from './interfaces/IOptions';
import HistoryManager from './services/HistoryManagerService';
import { applyElementStyles } from '@repo/ui-helpers';
import { Logger } from '@repo/common';

export default class IFrameApplication {
    private mountingElement: HTMLElement;
    private comeFromLobby: number = 0;
    private iframe: HTMLIFrameElement | null = null;
    private historyManager: HistoryManager;
    private settings: IOptions;
    /**
     * This is the External communication Channel, used to communicate with the Game Client
     */
    public EXTERNAL_CHANNEL = new Subject<IExternalChannel>();

    constructor(settings: IOptions, elementId: string = 'app-root') {
        this.settings = settings;
        this.mountingElement = IFrameApplication.getElement(elementId);
        this.historyManager = new HistoryManager({
            closeUrl: this.settings.closeUrl,
            isAggregator: this.settings.isAggregator,
            isDemo: this.settings.currency.toUpperCase() === 'EGT',
            gameKey: this.settings.gameKey,
        });
        applyElementStyles(this.settings.openType, this.settings.mode, this.mountingElement);
        this.loadUrlParams();
        this.createBgBlocker();
        this.listenForLobbyAppMessages();
        this.listenForGameClientMessages();
    }

    public static getElement(name: string): HTMLElement {
        let element = document.getElementById(name);
        if (!element) {
            element = document.createElement('div');
            element.setAttribute('id', name);
            document.body.appendChild(element);
        }
        return element;
    }

    public get channel(): Subject<IExternalChannel> {
        return this.EXTERNAL_CHANNEL;
    }

    public get element(): HTMLElement {
        return this.mountingElement;
    }

    public renderIframeApplication(): void {
        this.createIframe(`${this.settings.host}/?${this.encodeParams()}`);
        this.element.appendChild(this.iframe as HTMLIFrameElement);
    }

    private createIframe(src: string): void {
        this.iframe = document.createElement('iframe') as HTMLIFrameElement;
        this.iframe.setAttribute('allow', 'autoplay');
        this.iframe.src = src;
    }

    private listenForLobbyAppMessages(): void {
        window.addEventListener('message', event => {
            if (event.source === this.iframe?.contentWindow) {
                if (event.data.requestType && event.data.requestType === 'incoming') {
                    return;
                }
                Logger.log('iFrame App: Received event from Lobby App:', JSON.stringify(event.data));
                if (event.data.event === 'startLobbyGame') {
                    this.historyManager?.onStartNewGame(event.data.value);
                } else if (event.data.event === 'settingsModalToggle') {
                    this.iframe.setAttribute('scrolling', `${event.data.value ? 'no' : 'yes'}`);
                } else if (event.data.command === 'exit') {
                    // This is special command send from the Lobby App upon exit button click
                    // We need to capture it here in the Iframe App and propagate it up in order to notify
                    // the operator about the exit intent
                    this.historyManager?.onExitApplication();
                } else {
                    Logger.log('iFrame App: Propagating event to Game Client: ', JSON.stringify(event.data));
                    this.channel.next(event.data);
                }
            }
        });
    }

    private listenForGameClientMessages() {
        this.channel.subscribe(data => {
            // @ts-ignore
            if (data.requestType && data.requestType === 'outgoing') {
                return;
            }
            Logger.log('iFrame App: Received event from Game Client: ', JSON.stringify(data));

            if (data.event === 'exit') {
                this.historyManager?.onExitApplication();
                return;
            }

            // In the Iframe App notify the operator that the Lobby is opened
            if (data.event === 'display' && data.value && this.settings.sendOpenLobbyEvent) {
                window.parent.postMessage({ command: 'openLobby' }, '*');
            }

            // Game Client is updating the IFrame settings
            if (data.event === 'settings') {
                this.settings = {
                    ...this.settings,
                    ...(data.value as IOptions),
                };

                applyElementStyles(this.settings.openType, this.settings.mode, this.mountingElement);
            }

            this.iframe?.contentWindow?.postMessage(data, '*');
        });
    }

    private createBgBlocker(): void {
        const backgroundBlocker = document.createElement('div');
        backgroundBlocker.classList.add('egtd_background_blocker');
        this.mountingElement.appendChild(backgroundBlocker);
    }

    /**
     * Encodes all the lobby options
     */
    private encodeParams(): string {
        const settings = this.settings;

        return [
            ['apiIntegrationHost', settings.apiHost],
            ['assetsHost', settings.assetsHost],
            ['redirectHost', settings.redirectHost],
            ['mode', settings.mode],
            ['currency', settings.currency],
            ['balance', settings.balance],
            ['units', settings.units],
            ['languageCode', settings.languageCode],
            ['openType', settings.openType],
            ['mute', settings.mute],
            ['bgMusicMute', settings.bgMusicMute],
            ['gameKey', settings.gameKey],
            ['playerId', settings.playerId],
            ['comeFromLobby', this.comeFromLobby],
            ['iniframe', 'true'],
            ['closeUrl', settings.closeUrl],
            ['sendOpenLobbyEvent', settings.sendOpenLobbyEvent],
            ['mountLobby', settings.mountLobby],
            ['isAggregator', settings.isAggregator],
        ]
            .map(([key, value]) => `${key}=${encodeURI(String(value))}`)
            .join('&');
    }

    private loadUrlParams(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const comeFromLobby = urlParams.get('comeFromLobby');
        if (comeFromLobby) {
            this.comeFromLobby = parseInt(comeFromLobby, 10);
        }
    }
}
